import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap"
import {
  FaArrowAltCircleDown,
  FaPaintBrush,
  FaAddressCard,
} from "react-icons/fa"
import { FcPrint, FcPackage } from "react-icons/fc"

const branding = () => {
  return (
    <Layout>
      <Seo
        title="Your Brand Identify differentiates you from the crowd!"
        description="We developed an innovative approach that results in stand-out brand designs which builds trust. In essence it showcases who you are and what you do."
        keyword="Branding, Graphics Designing"
      />
      <Container fluid className="min-vh-100 position-relative">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row className="mt-5">
          <Col
            md={6}
            sm={12}
            className="align-items-center text-center my-auto"
          >
            <h1 className="text3d fs-3 mb-5">BRANDING</h1>
          </Col>
          <Col md={6} sm={12}>
            <StaticImage
              alt="Branding poster"
              src={"../images/Branding.png"}
              placeholder={"tracedSVG"}
              className="me-auto ms-auto ps-4"
              layout={"constrained"}
              formats={["auto", "webp", "avif"]}
            />
          </Col>
        </Row>
        <br /><br /><br /><br /><br />
        <Row className="text-center text-white">
          <Col>
            <h2 className="h2 luminance">
              Distinctive Design To Identify Your Brand
            </h2>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <div className="position-absolute bottom-0 start-50 text-white mb-1">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <p
            className="bg-text fs-5 text-wrap"
            data-aos="zoom-in-right"
          >
            <br />
            Branding and Brand identity is the most important part of growing
            your business. It shows your uniqueness and helps you stand tall
            among your competitors. It is the combination of sign, symbol design
            and most important it’s the people’s perception to identify your
            goods and services.
          </p>
          <br />
          <br />
          <Row className="opacity-75">
            <Col sm={12} md>
              <Card className="mb-5">
                <Card.Header className="text-danger">
                  <FaPaintBrush />
                </Card.Header>
                <Card.Body>
                  <Card.Text>Logo Designing</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md>
              <Card className="mb-5">
                <Card.Header>
                  <FcPrint />
                </Card.Header>
                <Card.Body>
                  <Card.Text>Print Designing</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md>
              <Card className="mb-5">
                <Card.Header className="text-danger">
                  <FcPackage />
                </Card.Header>
                <Card.Body>
                  <Card.Text>Packaging Design</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md>
              <Card className="mb-5">
                <Card.Header className="text-danger">
                  <FaAddressCard />
                </Card.Header>
                <Card.Body>
                  <Card.Text>Business Card Design</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <p>
            We here at softcode understand the importance of your brand identity
            and hence have very creative and skillful world class graphic
            designer to portray you and your brand.
            <br />
            <br />
            We help you create brand awareness so people know your brand
            completely and thus don’t seek for additional information before
            making any purchase, hence increasing the probability of your goods
            and services in the market.
          </p>
          <br />
          <br />
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          OUR SERVICES
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          <Row>
            <Col sm={12} md>
              <ListGroup className="mb-5">
                <ListGroup.Item as="li">Logo Design</ListGroup.Item>
                <ListGroup.Item as="li">Business card Design</ListGroup.Item>
                <ListGroup.Item as="li">User Manual Design</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md>
              <ListGroup className="mb-5">
                <ListGroup.Item as="li">Brochure Design</ListGroup.Item>
                <ListGroup.Item as="li">Print Design</ListGroup.Item>
                <ListGroup.Item as="li">Packaging Design</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md>
              <ListGroup className="mb-5">
                <ListGroup.Item as="li">Letterhead Design</ListGroup.Item>
                <ListGroup.Item as="li">Flyer Design</ListGroup.Item>
                <ListGroup.Item as="li">Banner Design</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default branding
